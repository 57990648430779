import Cookies from 'js-cookie'
import axios from 'axios'
import { cookieFromRequest } from '~/utils'

export const actions = {
    nuxtServerInit ({ commit }, { req }) {
        const token = cookieFromRequest(req, 'token')
        if (token) {
            commit('auth/SET_TOKEN', token)
        }

        const locale = cookieFromRequest(req, 'locale')
        if (locale) {
            commit('lang/SET_LOCALE', { locale })
        }

        axios.get('/locale')
            .then((rsp) => {
                commit('data/SET_LOCALE_VARIABLES', rsp.data)
            })

        // Cached
        axios.get('/industries?format=select')
            .then(rsp => rsp.data)
            .then((rsp) => {
                commit('data/SET_INDUSTRIES', rsp.data)
            })

        // Cached
        axios.get('/price-tiers')
            .then(rsp => rsp.data)
            .then((rsp) => {
                commit('data/SET_PRICE_TIERS', rsp)
            })

        // Cached
        axios.get('/licences')
            .then(rsp => rsp.data)
            .then((rsp) => {
                commit('data/SET_LICENCES', rsp)
            })

        axios.get('/promotions')
            .then(rsp => rsp.data)
            .then((rsp) => {
                commit('data/SET_PROMOTIONS', rsp)
            })
    },

    nuxtClientInit ({
        commit,
        getters
    }) {
        const token = Cookies.get('token')
        if (token && !getters['auth/token']) {
            commit('auth/SET_TOKEN', token)
        }

        const locale = Cookies.get('locale')
        if (locale && !getters['lang/locale']) {
            commit('lang/SET_LOCALE', { locale })
        }
    }
}
